@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.custom-rbc {
}

.custom-rbc .rbc-month-row {
  border: none !important;
  min-height: 100px;
}

.custom-rbc .rbc-header {
  border: none !important;
}

.custom-rbc .rbc-month-view {
  border: none !important;
}

.custom-rbc .rbc-day-bg {
  border: none !important;
}

.custom-rbc .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  padding: 0 !important;
  background-color: transparent !important;
}

.custom-rbc .rbc-date-cell {
  text-align: center !important;
  padding: 4px;
}

.custom-rbc .rbc-row-content-scrollable .rbc-row-content-scroll-container {
  overflow-y: unset;
}

.react-datepicker-wrapper {
  width: 100%;
}

div[class*="menu-dropdown"] {
  z-index: 20 !important;
}

